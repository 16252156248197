<style lang="scss">
.flex_center {
    align-items: center;

    .icon-size {
        font-size: 24px;
        cursor: pointer;
    }

    .icon-color {
        color: #6684EC;
    }
}
</style>
<template>
    <div class="ts-table flex flex-item flex-column">
        <div class=" flex flex_center" style="height: 35px;justify-content: space-between;padding: 0 10px;">
            <div class=" flex flex_center">
                <el-radio-group v-model="radioText" style="margin-right: 5px;" @input="clickRadioText">
                    <el-radio-button label="1">新药申报</el-radio-button>
                    <el-radio-button label="2">获批新药</el-radio-button>
                </el-radio-group>
                <convenient-filtering v-if="isRadio" :screenName="screenName" @searchTime="searchTime"
                    :newScreenList="screenList" style="margin-left: 0;" class="flex_center"></convenient-filtering>
            </div>
            <div class=" flex flex_center">
                <i class="iconfont icon-biaoge icon-size" :class="isicon === 'left' ? 'icon-color' : ''"
                    @click="clickIcon('left')"></i>
                <span style="font-size: 18px;color: #9d9d9d;margin: 0 10px;">|</span>
                <i class="iconfont icon-tuxing icon-size" :class="isicon === 'right' ? 'icon-color' : ''"
                    @click="clickIcon('right')"></i>
            </div>
        </div>
        <!-- 表格 -->
        <ts-table v-if="isicon === 'left'" ref="table" class="flex-item" :cols="cols" :data="list"
            :page-param="pageParam" :loading="loading" :name="nameKey" @handleSort="sort" :error="pageParam.error"
            :error-code="error" @handleRowDbClickChange="handleRowDbClickChange" full
            @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
            <template #searchLeft>
                <ts-search-left v-if="isSearchLeftThree" :searchCols="searchCols" :cols="cols" @searchClose="search"
                    @handleFilter="handleFilter" table-name="aim_base_drugs_winning"></ts-search-left>
            </template>
        </ts-table>
        <!-- 图表 -->
        <div v-else-if="isicon === 'right'" :id="nameKey" class="canvas" v-loading="loading"></div>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
export default {
    props: ['isSearchLeftThree'],
    mixins: [myMixin],
    data() {
        return {
            nameKey: 'statisticalTable',
            pageApi: 'indexDrug/aimBaseDrugsWinning/queryWinningPage',

            screenName: 'winningTime',
            screenList: [
                {
                    key: 'oneMonths',
                    value: '30天内',
                    num: 1,
                },
                {
                    key: 'twoMonths',
                    value: '60天内',
                    num: 2,
                },
                {
                    key: 'threeMonths',
                    value: '90天内',
                    num: 3,
                },
                {
                    key: 'allData',
                    value: '全部',
                    num: 0,
                },
            ],
            radioText: '1',
            isicon: 'left',
            isRadio: true,
            defaultConditionList:[],


            cols: this.$help.generalCols([
                {
                    fieldName: 'compare',
                    name: '对比',
                    listClass: 'tc',
                    width: 50,
                },
                {
                    fieldName: 'price',
                    name: '价格趋势',
                    listClass: 'tc',
                    width: 70,
                },
                {
                    fieldName: 'priceState',
                    name: '近期趋势',
                    listClass: 'tc',
                    listOrderView: '是',
                    width: 90,
                },
                {
                    fieldName: 'fivePriceState',
                    name: '近5年趋势',
                    listClass: 'tc',
                    width: 90,
                },
                this.$variable.indexColumn.drug(),
                {
                    fieldName: 'compName',
                    name: '企业',
                    width: 180,
                    listQueryView: '是',
                    listQueryModel: 1,
                    listView: '是',
                    listOrderView: '是',
                },
                {
                    fieldName: 'pack',
                    name: '制剂单位',
                    listQueryView: "是",
                    listClass: 'tc',
                    listOrderView: '是',
                },
                {
                    fieldName: 'unifySpec',
                    name: '规格',
                    width: 100,
                    listQueryView: "是",
                    listQueryModel: 1,
                    listOrderView: '是',
                },
                {
                    fieldName: 'winProvinceNumber',
                    name: '中标省份',
                    listOrderView: '是',
                    width: 100,
                    link: this.goBid,
                    listClass: 'tc'
                },
                {
                    fieldName: 'minUnitPrice',
                    name: '最新制剂价',
                    width: 80,
                    listOrderView: '是',
                    listClass: 'tc'
                },
                {
                    fieldName: 'conversionRatio',
                    name: '转换比',
                    width: 80,
                    listOrderView: '是',
                    listClass: 'tc'
                },
                {
                    fieldName: 'minPrice',
                    name: '单价(最低)',
                    listOrderView: '是',
                    width: 100,
                    link: this.goBid,
                    listClass: 'tc'
                },
                {
                    fieldName: 'avgPrice',
                    name: '单价(均价)',
                    listOrderView: '是',
                    width: 100,
                    link: this.goBid,
                    listClass: 'tc'
                },
                {
                    fieldName: 'maxPrice',
                    name: '单价(最高)',
                    listOrderView: '是',
                    width: 100,
                    link: this.goBid,
                    listClass: 'tc'
                },
                {
                    fieldName: 'winNumber',
                    name: '中标记录',
                    width: 100,
                    listOrderView: '是',
                    link: this.goBid,
                    listClass: 'tc'
                },
                {
                    fieldName: 'winningTime',
                    name: '最新中标时间',
                    width: 120,
                    listOrderView: '是',
                    listClass: 'tc'
                },
                {
                    fieldName: 'purchaseBatch',
                    name: '省份集采',
                    width: 95,
                    listOrderView: '是',
                    link: this.goNationProcurement,
                    listClass: 'tc'
                },
            ]),

        };
    },
    methods: {
        search() {
            this.$emit('searchClose')
        },
        //便捷时间筛选
        searchTime(val) {
            let fleg = true
            let cndList = []
            if (val && val.length != 0) {
                for (let index = 0; index < val.length; index++) {
                    if (!val[index].value) {
                        fleg = false
                    }
                    if (cndList && cndList.length != 0) {
                        cndList.forEach((item, i) => {
                            if (item.name === this.screenName) {
                                cndList.splice(i, 1)
                            }
                        })
                    }
                }
            } else {
                fleg = false
            }
            cndList = !fleg ? cndList : cndList.concat(val)
            this.defaultConditionList = cndList
            console.log('时间筛选', cndList, this.defaultConditionList);
            this.getUrl()
        },
        clickRadioText(e) {
            console.log('选择框', e, this.radioText);
            this.clickIcon('left')
        },
        clickIcon(text) {
            this.isicon = text
            this.isRadio = false
            this.defaultConditionList = []
            setTimeout(() => {
                this.isRadio = true
            }, 10)
            this.getUrl()
        },
        getUrl(){
            if (this.isicon  === 'right') {
                this.showDoseRate()
            }else{
                this.init()
            }
        },

        showDoseRate() {
            this.loading = true
            this.$api.post('indexDrug/saleYear/drugDoseRatioAndAmount', {
                model: 2023,
                params: { comName: "%氯雷他定%" },
            })
                .then(res => {
                    let data = []
                    let total = 0
                    res.data
                        .sort((x, y) => {
                            return parseFloat(y.amount) - parseFloat(x.amount)
                        })
                        .splice(10)
                    data = res.data
                        .map(item => {
                            const value = this.$help.parseFloat100(item.ratio)
                            item.name = item.unifyDose
                            item.value = value
                            total += value
                            return item
                        })
                    if (total < 100 && this.data.length === 10) {
                        data.push({
                            name: '其他',
                            value: 100 - total
                        })
                    }
                    this.$help.renderPie(this.nameKey, data)
                })
                .finally(() => {
                    this.loading = false
                })
        },
    }

}
</script>
<style lang='scss'></style>